import styled, { CSSObject } from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Link } from '~/shared/components';
import { cover } from '~/shared/utils/styled';
import { breakpoints } from '~/theme';
import ArrowRight from '$icons/arrow-right.svg';

interface StyledArticleProps {
    columns: number;
    isCarousel: boolean;
}

interface StyledImageWrapperProps {
    columns: number;
}

export const StyledArticle = styled.div<StyledArticleProps>(
    {
        'p:last-of-type': {
            marginBottom: 0,
        },
    },
    ifProp(
        { isCarousel: true },
        {
            '&:active': {
                cursor: 'grabbing',
            },
        }
    ),
    ifProp(
        { isCarousel: false },
        {
            width: '100%',
            minWidth: 0, // Fix shrinking below grid cell value
        }
    ),
    ifProp(
        { columns: 2, isCarousel: true },
        {
            width: 300,
            [breakpoints.sm]: {
                width: '100%',
            },
        }
    ),
    ifProp(
        { columns: 3, isCarousel: true },
        {
            width: 300,
            [breakpoints.sm]: {
                width: '100%',
            },
        }
    ),
    ifProp(
        { columns: 4, isCarousel: true },
        {
            width: 300,
            [breakpoints.sm]: {
                width: '100%',
            },
        }
    )
);

export const StyledActionWrapper = styled.div({});

export const StyledContent = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spaces[7],
    gap: theme.spaces[4],
}));

export const StyledImageWrapper = styled.div<StyledImageWrapperProps>(
    ({ theme }) => ({
        height: '400px',
        position: 'relative',
        borderRadius: '45px 15px',
        overflow: 'hidden',
        img: {
            backgroundColor: theme.colors.grey05,
            borderRadius: '45px 15px',
        },
    }),
    ifProp(
        { columns: 2 },
        {
            height: 0,
            paddingBottom: '70%',
            [breakpoints.md]: {
                paddingBottom: '56.25%',
            },
        }
    ),
    ifProp(
        { columns: 3 },
        {
            height: 0,
            paddingBottom: '70%',
            [breakpoints.md]: {
                paddingBottom: '80%',
            },
        }
    )
);

export const StyledLink = styled(Link)(
    ({ theme }) =>
        ({
            color: theme.colors.black,
            textDecoration: 'none',
            display: 'inline-flex',
            alignItems: 'center',
            gap: theme.spaces[2],
            ':after': {
                content: '""',
                ...cover,
            },
        } as CSSObject)
);

export const StyledArrowRight = styled(ArrowRight)({
    maxWidth: 11,
});
