import React, { useMemo, useRef, useState } from 'react';
import { M80MediaAndTextBundleModule } from '~/lib/data-contract';
import { ModuleContainer } from '../ModuleContainer';
import { Article } from './components/Article';
import { itemMapper } from './utils/itemMapper';
import {
    StyledCarouselButtonContainer,
    StyledCarouselWrapper,
    StyledContentCenter,
    StyledGridWrapper,
    StyledLoadMoreButton,
    StyledRelativeContainer,
} from './styled';
import { Gutter, MaxWidth, ModuleHeader } from '~/shared/components';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import 'swiper/css';
import SwiperCore, { SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSwiperHelper } from '~/shared/hooks/useSwiperHelper/useSwiperHelper';
import { CarouselButton } from '~/shared/components/Carousel/components/CarouselButton/CarouselButton';
import { useTranslation } from '~/shared/utils/translation';
import ArrowDown from '$icons/arrow-down.svg';

export type M80MediaTextBundleProps = M80MediaAndTextBundleModule;

export const M80MediaTextBundle = ({
    headline,
    description,
    callToAction,
    mediaAndTextItems = [],
    columns = 3,
    isCarousel = false,
    pageElementIndex,
    loadMore,
    ...rest
}: M80MediaTextBundleProps) => {
    const moduleWidthRef = useRef<HTMLDivElement>(null);
    const [swiperInstance, setSwiperInstance] = useState<SwiperCore | undefined>();
    const [isHiddenArticles, setIsHiddenArticles] = useState(!isCarousel && loadMore);

    const maxNumberOfArticles = columns * 2;

    const { translate } = useTranslation();

    const { hasNext, hasPrev, slideNext, slidePrev } = useSwiperHelper(swiperInstance);

    const articlesList = useMemo(() => {
        const mediaItems = itemMapper(mediaAndTextItems);
        return mediaItems?.map((item, index) => (
            <Article key={index} {...item} isCarousel={isCarousel} columns={columns} />
        ));
    }, [mediaAndTextItems]);

    const articles = useMemo(
        () => (isHiddenArticles ? articlesList.slice(0, maxNumberOfArticles) : articlesList),
        [articlesList, isHiddenArticles],
    );

    const slidesConfig = useMemo(() => {
        const config = {
            slidesPerView: 'auto' as const,
            slidesPerGroup: 1,
            slidesPerGroupAuto: true,
            breakpoints: {
                768: {
                    spaceBetween: 30,
                    slidesPerGroup: 2,
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerGroup: 2,
                    slidesPerView: 2,
                },
            },
        };
        if (columns === 4) {
            config.breakpoints[1024] = {
                slidesPerGroup: 4,
                slidesPerView: 4,
            };
            return config;
        }
        if (columns === 3) {
            config.breakpoints[1024] = {
                slidesPerGroup: 3,
                slidesPerView: 3,
            };
            return config;
        }

        config.breakpoints[1024] = {
            slidesPerGroup: 2,
            slidesPerView: 2,
        };
        return config;
    }, [columns]);

    const handleToggleShowMore = () => {
        setIsHiddenArticles((currentValue) => !currentValue);
    };
    const swiperConfig: SwiperOptions = {
        autoplay: false,
        spaceBetween: 20,
        speed: 500,
        mousewheel: {
            forceToAxis: true, // Enables horizontal scroll, but prevents carousel from hijacking regular vertical scroll
        },
        preventClicksPropagation: true, // Fix buttons and links inside carousel causing a jump when dragged.
        updateOnImagesReady: true,
        ...slidesConfig,
    };

    return (
        <ModuleContainer fullWidth hasGutter={false} pageElementIndex={pageElementIndex} {...rest}>
            {headline && (
                <MaxWidth>
                    <Gutter>
                        <ModuleHeader
                            headline={headline}
                            description={description}
                            callToAction={callToAction}
                        />
                    </Gutter>
                </MaxWidth>
            )}
            {isCarousel && articles.length > 0 ? (
                <StyledCarouselWrapper ref={moduleWidthRef}>
                    <MaxWidth>
                        <Gutter>
                            <StyledRelativeContainer>
                                {hasPrev && (
                                    <StyledCarouselButtonContainer alignment="left">
                                        <CarouselButton onClick={slidePrev} direction={'left'} />
                                    </StyledCarouselButtonContainer>
                                )}
                                {hasNext && (
                                    <StyledCarouselButtonContainer alignment="right">
                                        <CarouselButton onClick={slideNext} direction={'right'} />
                                    </StyledCarouselButtonContainer>
                                )}
                                <Swiper
                                    {...swiperConfig}
                                    onSwiper={(instance) => {
                                        setSwiperInstance(instance);
                                    }}
                                >
                                    {articles.map((item, index) => (
                                        <SwiperSlide key={index}>{item}</SwiperSlide>
                                    ))}
                                </Swiper>
                            </StyledRelativeContainer>
                        </Gutter>
                    </MaxWidth>
                </StyledCarouselWrapper>
            ) : (
                <MaxWidth>
                    <Gutter>
                        <StyledGridWrapper columns={columns}>{articles}</StyledGridWrapper>
                    </Gutter>
                    {loadMore && isHiddenArticles && articlesList?.length > maxNumberOfArticles && (
                        <StyledContentCenter>
                            <StyledLoadMoreButton
                                disableHoverAnimation={true}
                                variant="Transparent"
                                icon={<ArrowDown />}
                                iconAlignment="end"
                                onClick={handleToggleShowMore}
                            >
                                {translate('Kompan.Commerce.LoadMore')}
                            </StyledLoadMoreButton>
                        </StyledContentCenter>
                    )}
                </MaxWidth>
            )}
        </ModuleContainer>
    );
};

export default withErrorBoundary(M80MediaTextBundle);
