import styled from '@emotion/styled';
import { StyledImageWrapper } from '../Image/styled';
import { ifProp } from 'styled-tools';

export const StyledImageLinkInner = styled.div<{ animationDuration?: number }>(
    ({ theme }) => ({
        transform: 'scale(1)',
        willChange: 'transform',
        transition: `transform ${theme.animations.easeOutQuint} ${theme.animations.timingLongest}`,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',

        [`${StyledImageWrapper}`]: {
            display: 'inline-block',
            height: '100%',
            width: '100%',
        },
    }),
    ifProp('animationDuration', ({ animationDuration }) => ({
        transitionDuration: `${animationDuration}ms`,
    }))
);

export const StyledImageLinkWrapper = styled.div<{
    withBorderRadius?: boolean;
    withAnimation: boolean;
    shouldFill?: boolean;
}>(
    () => ({
        height: '100%',
        width: '100%',
    }),
    ifProp('withAnimation', () => ({
        ':hover': {
            [`${StyledImageLinkInner}`]: {
                transform: 'scale(1.1)',
            },
        },
    })),
    ifProp('withBorderRadius', {
        overflow: 'hidden',
        transform: 'translateZ(0)',
        borderRadius: '45px 15px',
        img: {
            borderRadius: '45px 15px', // Duplicate borderRadius fixes edges sometimes looking cut off.
        },
    }),
    ifProp('shouldFill', () => ({
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
    }))
);
