import { MediaAndTextItem, PageReference } from '~/lib/data-contract';

/**
 * Maps reference items to media and text items
 *
 * @param items mixed models of IMediaAndTextItem & IPageReference
 * @returns Array of IMediaAndTextItem
 */
export const itemMapper = (items: (MediaAndTextItem | PageReference)[]): MediaAndTextItem[] => {
    return items
        ?.map(
            (item): MediaAndTextItem => {
                const cta = item.callToAction;
                return isPageReference(item)
                    ? {
                          headline: item.pageReferenceTitle,
                          richTextDescription: item.pageReferenceDescription
                              ? `<p>${item.pageReferenceDescription}</p>`
                              : undefined,
                          image: item.pageReferenceImage,
                          callToAction: cta
                              ? {
                                    text: cta.title,
                                    title: cta.title,
                                    url: cta.url,
                                    variation: 'Plain',
                                    iconAlignment: 'start',
                                    iconName: 'Arrow Right',
                                }
                              : undefined,
                          type: 'blockPageReference',
                      }
                    : item;
            }
        )
        .filter((item) => {
            return (
                (item.type == 'blockPageReference' && item.image?.src && item?.callToAction?.url) ||
                item.type == 'blockContentArticle'
            );
        });
};

const isPageReference = (item: MediaAndTextItem | PageReference): item is PageReference => {
    return item.type === 'blockPageReference';
};
