import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { ifProp } from 'styled-tools';
import { Button } from '~/shared/components';
import { breakpoints } from '~/theme';

interface StyledGridWrapperProps {
    columns: number;
}

export const StyledGridWrapper = styled.div<StyledGridWrapperProps>(({ theme, columns }) => ({
    display: 'grid',
    gridTemplateColumns: `1fr`,
    columnGap: theme.spaces[6],
    rowGap: '4.4rem',
    [breakpoints.sm]: {
        gridTemplateColumns: `repeat(2, 1fr)`,
    },
    [breakpoints.md]: {
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
    },
}));

export const StyledRelativeContainer = styled.div({
    position: 'relative',
});

export const StyledCarouselButtonContainer = styled(motion.div)<{
    alignment: 'left' | 'right';
}>(
    ({ theme }) => ({
        position: 'absolute',
        top: '33%',
        opacity: 0,
        transform: 'translateY(-50%)',
        zIndex: 1000,
        cursor: 'pointer',
        transition: `${theme.animations.timingShort} ${theme.animations.easeOutCubic}`,
        transitionProperty: 'opacity',
    }),
    ifProp(
        { alignment: 'left' },
        {
            left: 10,
            [breakpoints.lg]: {
                left: 40,
            },
        },
        {
            right: 10,
            [breakpoints.lg]: {
                right: 40,
            },
        },
    ),
);

export const StyledCarouselWrapper = styled.div(() => ({
    position: 'relative',
    overflow: 'hidden',

    [`${StyledCarouselButtonContainer}`]: {
        opacity: 1,
    },
    '.swiper-slide': {
        width: 'auto',
    },
    '.swiper': {
        overflow: 'visible',
    },

    [breakpoints.md]: {
        [`${StyledCarouselButtonContainer}`]: {
            opacity: 0,
        },
        [`&:hover ${StyledCarouselButtonContainer}`]: {
            opacity: 1,
        },
    },
}));

export const StyledLoadMoreButton = styled(Button)(
    ({ theme }) => ({
        margin: `${theme.spaces[2]} auto`,
        width: 'auto',
        minWidth: '150px',
    }),
    ifProp('disabled', () => ({
        opacity: 0.5,
        pointerEvents: 'none',
    })),
);

export const StyledContentCenter = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'center',
    marginTop: theme.spaces[6],
}));
