import NextLink, { LinkProps } from 'next/link';
import { ImageProps } from '../Image/Image';
import { Link, Image, StyledLink } from '$shared/components';
import { StyledImageLinkInner, StyledImageLinkWrapper } from './styled';
import { forwardRef } from 'react';
import { CSSObject } from '@emotion/react';
export interface ImageLinkProps {
    linkProps: LinkProps;
    imageProps: ImageProps;
    withAnimation?: boolean;
    animationDuration?: number;
    withBorderRadius?: boolean;
    css?: CSSObject;
}

export const ImageLink = forwardRef<
    HTMLAnchorElement,
    ImageLinkProps & React.ComponentPropsWithRef<typeof StyledLink>
>(
    (
        {
            linkProps,
            imageProps,
            animationDuration,
            withBorderRadius = false,
            withAnimation = true,
            target,
            ...rest
        },
        ref,
    ) => {
        return (
            <NextLink {...linkProps} target={target} legacyBehavior passHref>
                <Link ref={ref} {...rest} target={target}>
                    <StyledImageLinkWrapper
                        withAnimation={withAnimation}
                        withBorderRadius={withBorderRadius}
                        shouldFill={imageProps.layout == 'fill'}
                    >
                        <StyledImageLinkInner animationDuration={animationDuration}>
                            <Image {...imageProps}></Image>
                        </StyledImageLinkInner>
                    </StyledImageLinkWrapper>
                </Link>
            </NextLink>
        );
    },
);
