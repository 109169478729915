import { Text, CallToAction, Image, ImageLink, useTracking, RawHtml } from '$shared/components';
import React, { useMemo, memo } from 'react';
import { ImageMedia, MediaAndTextItem } from '~/lib/data-contract';
import { queries, breaks } from '~/theme/breakpoints';
import { StyledArticle, StyledContent, StyledImageWrapper, StyledActionWrapper } from './styled';
import { useTranslation } from '$shared/utils/translation';

export interface ArticleProps extends MediaAndTextItem {
    isCarousel: boolean;
    columns: number;
}

const MemoizedCallToAction = memo(CallToAction);

export const Article = ({
    headline,
    richTextDescription,
    image,
    callToAction,
    isCarousel,
    columns,
    type,
}: ArticleProps) => {
    const { trackLink } = useTracking();
    const { translate } = useTranslation();

    const imageProps = useMemo(() => {
        switch (columns) {
            case 2:
                return {
                    height: 300,
                    width: 340,
                    size: `${queries.xs} 100vw, ${queries.sm} 50vw, ${queries.pageMax} ${
                        breaks.pageMax / 2
                    }px`,
                };
            case 4:
                return {
                    height: 0,
                    width: 340,
                    size: `${queries.xs} 100vw, ${queries.sm} 25vw, ${queries.pageMax} ${
                        breaks.pageMax / 4
                    }px`,
                };
            default:
                return {
                    height: 0,
                    width: 340,
                    size: `${queries.xs} 100vw, ${queries.sm} 33.33vw, ${queries.pageMax} ${
                        breaks.pageMax / 3
                    }px`,
                };
        }
    }, [columns]);

    const getImage = (image?: ImageMedia) => {
        return image?.src ? (
            <Image
                alt={headline || ''}
                {...image}
                objectFit="cover"
                layout="fill"
                sizes={imageProps.size}
                draggable={false}
            />
        ) : null;
    };

    return (
        <StyledArticle isCarousel={isCarousel} columns={columns}>
            <StyledImageWrapper columns={columns}>
                {callToAction?.url
                    ? image?.src && (
                          <ImageLink
                              withBorderRadius
                              onClick={() => trackLink(callToAction, headline)}
                              imageProps={{
                                  alt: headline || '',
                                  ...image,
                                  src: image?.src,
                                  layout: 'fill',
                                  sizes: imageProps.size,
                                  draggable: false,
                                  objectFit: 'cover',
                              }}
                              target={callToAction?.target}
                              linkProps={{
                                  href: callToAction.url,
                                  passHref: true,
                                  prefetch: !isCarousel,
                              }}
                              draggable={false}
                          />
                      )
                    : getImage(image)}
            </StyledImageWrapper>
            <StyledContent>
                {headline && <Text variant="display4" children={headline} />}
                {!headline && callToAction?.title && (
                    <Text variant="display4">{callToAction?.title}</Text>
                )}
                {richTextDescription && <RawHtml html={richTextDescription} />}
                {callToAction && (
                    <StyledActionWrapper>
                        <MemoizedCallToAction
                            prefetch={!isCarousel}
                            callToAction={{
                                ...callToAction,
                                text:
                                    type === 'blockPageReference'
                                        ? translate('Kompan.RelevantContent.ButtonLabelSeeMore')
                                        : callToAction.text || callToAction.title,
                                variation: 'Plain',
                                iconName: 'arrow right',
                                iconAlignment: 'start',
                            }}
                            itemHeadline={headline}
                        />
                    </StyledActionWrapper>
                )}
            </StyledContent>
        </StyledArticle>
    );
};
